import React, { ReactNode } from 'react';
import { Table } from 'reactstrap';

interface Props {
  children: ReactNode;
}

const RateTable = ({ children }: Props) => {
  return (
    <Table size="sm" hover striped>
      {children}
    </Table>
  );
};

export default RateTable;
