import React from 'react';
import _ from 'lodash';
import RateTable from './RateTable';
import { rateTableWidth } from '../../constants';

interface Props {
  rates: { term: string; apr: string; }[];
}

const TermAprTable = ({ rates }: Props) => {
  return (
    <RateTable>
      <thead>
        <tr>
          <th>Term</th>
          <th style={{ width: rateTableWidth }}>APR*</th>
        </tr>
      </thead>
      <tbody>
        {
          _.map(rates, (rate, i) => (
            <tr key={i}>
              <td>{rate.term}</td>
              <td>{rate.apr}</td>
            </tr>
          ))
        }
      </tbody>
    </RateTable>
  );
};

export default TermAprTable;
